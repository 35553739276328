import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {Icon, Style, Fill, Stroke} from 'ol/style'
import {Vector as VectorSource} from 'ol/source'
import {Vector as VectorLayer} from 'ol/layer'
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString'

import santaPng from './assets/santa.png'
import presentPng from './assets/present.png'

const apiUrl = 'https://thpwko06kj.execute-api.eu-west-2.amazonaws.com/production';

let firstLoad = true;

const NORTH_POLE_LOCATION = {
  latitude: 79.9385,
  longitude: 20.1661,
  zoom: 6
}

window.onresize = function()
{
  setTimeout(function() {map.updateSize();}, 200);
}

const ZOOM_LEVEL = 5;

const trailStyle = new Style({
  image: new Icon({
    src: presentPng,
    scale: [0.02, 0.02]
  }),
});
const trailSource = new VectorSource({});
const trailLayer = new VectorLayer({
  source: trailSource,
  style: new Style({
    fill: new Fill({ color: '#c33', weight: 4 }),
    stroke: new Stroke({ color: '#c33', width: 2 })
  })
});

const santaStyle = new Style({
  image: new Icon({
    src: santaPng,
    scale: [0.08, 0.08]
  }),
});
const santaSource = new VectorSource({});
const santaLayer = new VectorLayer({
  source: santaSource,
});

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    }),
    trailLayer,
    santaLayer
  ],
  view: new View({
    center: fromLonLat([NORTH_POLE_LOCATION.longitude, NORTH_POLE_LOCATION.latitude]),
    zoom: ZOOM_LEVEL
  })
});

let atNorthPole = false;

setLionsSpecific();

function displayPositions(positions) {
  santaSource.clear();
  trailSource.clear();

  const trail = positions.slice(1);
  const trailPositions = trail.map(pos => fromLonLat([pos.longitude, pos.latitude]))
  const santaPosition = fromLonLat([positions[0].longitude, positions[0].latitude]);

  const santaFeature = new Feature({
    geometry: new Point(santaPosition),
    name: 'Santa',

  });
  santaFeature.setStyle(santaStyle);

  santaSource.addFeature(santaFeature);
  if (positions[0].zoom && !atNorthPole) {
    map.getView().setZoom(4);
    map.getView().setCenter(santaPosition);
    atNorthPole = true;
  }
  else if (!positions[0].zoom && atNorthPole) {
    atNorthPole = false;
    map.getView().setZoom(ZOOM_LEVEL);
    map.getView().setCenter(santaPosition);
  }

  if (firstLoad) {
    map.getView().setCenter(santaPosition);
    firstLoad = false;
  }

  if (!atNorthPole) {
    let lastPosition = null;
    trailPositions.forEach(position => {
      const trailFeature = new Feature({
        geometry: new Point(position),
      });
      trailFeature.setStyle(trailStyle);
      trailSource.addFeature(trailFeature);

      if (lastPosition != null) {
        const trailLine = new Feature({
          geometry: new LineString([lastPosition, position])
        });
        trailSource.addFeature(trailLine);
      }
      lastPosition = position;
    });
    const trailLine = new Feature({
      geometry: new LineString([trailPositions[0], santaPosition])
    });
    trailSource.addFeature(trailLine);
  }

}

function getPositions() {
  const url = apiUrl + "/getdevicepositions";
  const headers = new Headers();
  fetch(url, { headers })
    .then(res => res.json())
    .then(data => {
      if (data.Results.length === 0) displayPositions([NORTH_POLE_LOCATION]);
      else {
        const date = new Date(data.Results[0].infodate);
        document.getElementById("info-date").textContent = `Last updated: ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")} (Santa may be slightly ahead of his position shown on the map)`
        displayPositions(data.Results);
      }
    });
}

function setLionsSpecific() {
  var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1].toLowerCase();

  if(domain.includes("test") || domain.includes("local")) {
    document.getElementById("route-link").href = `https://www.wetherbylions.org/_files/ugd/c5f434_9ccb892db56f4aabbd9b8cd01cfb6149.pdf`;
  }
  else if(domain.includes("swanwick")){
    document.getElementById("route-link").style.display = 'none';
    document.getElementById("lions-link").href = `https://www.swanwicklions.org.uk`;
    document.getElementById("lions-link").textContent = `https://www.swanwicklions.org.uk`;
  }
  else if(domain.includes("easingwold")){
    document.getElementById("route-link").style.display = 'none';
    //document.getElementById("route-link").href = `https://www.e-clubhouse.org/sites/easingwold/page-9.php`;
    document.getElementById("lions-link").href = `http://www.easingwold-lions.org/`;
    document.getElementById("lions-link").textContent = `http://www.easingwold-lions.org/`;
  }
  else if(domain.includes("wetherby")){
    document.getElementById("route-link").style.display = 'none';
    //document.getElementById("route-link").href = `https://www.wetherbylions.org/_files/ugd/c5f434_9ccb892db56f4aabbd9b8cd01cfb6149.pdf`;
  }

}

function getPositonsLoop(self) {
  getPositions()
  window.setTimeout(() => self(self), 15000)
}
//disabled to reduce costs until site is needed again.
//getPositonsLoop(getPositonsLoop);
atNorthPole = false;
displayPositions([NORTH_POLE_LOCATION]);